import { AUTHSERVICE } from "../config";

export const getTrackNaturalClient = async ({
  requestNumber,
  view
}) => {
  let url = "/request/track";  
  if (requestNumber) url += `?requestNumber=${encodeURIComponent(requestNumber)}`;
  if(view) url += `&view=${view}`;
  return await AUTHSERVICE().get(url);
};


export const postCreatePassword = async (formData) => {
  const url = "/onboarding/userCreation";
  return await AUTHSERVICE().post(url,formData);
};

