export const CedPana = ({ height = 32, width = 50 }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 50 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
     <rect width="50" height="32" rx="3.01887" fill="url(#paint0_linear_551_6180)"/>
<path d="M26.4382 21.5V21.4H26.3382H21H20.9V21.5V23.1239V23.2239H21H21.1665H21.2525L21.2654 23.1389C21.3302 22.7122 21.4834 22.3944 21.7171 22.1746L21.7173 22.1745C21.8714 22.0289 22.1313 21.9451 22.5208 21.9451H22.8503V26.4735C22.8503 26.6225 22.8463 26.741 22.8386 26.8299C22.8306 26.9229 22.8193 26.9715 22.8111 26.9896L22.811 26.9896L22.8105 26.9909C22.7732 27.0764 22.7264 27.1274 22.6747 27.1555L22.6746 27.1555L22.6717 27.1572C22.5869 27.2071 22.4676 27.2363 22.3061 27.2363H22.1088H22.0088V27.3363V27.5V27.6H22.1088H25.2206H25.3206V27.5V27.3363V27.2363H25.2206H25.0234C24.8644 27.2363 24.7495 27.2099 24.6708 27.166C24.5875 27.1147 24.5391 27.0612 24.5146 27.0087C24.5053 26.9855 24.4943 26.9327 24.4864 26.839C24.4787 26.747 24.4747 26.6255 24.4747 26.4735V21.9451H24.8174C25.0938 21.9451 25.2677 21.9724 25.3567 22.0148C25.5414 22.1054 25.6838 22.2258 25.7869 22.3754L25.7874 22.3761C25.8904 22.5227 25.9906 22.7763 26.0834 23.1481L26.1023 23.2239H26.1804H26.3382H26.4382V23.1239V21.5ZM27.4373 21.8691C27.495 21.9101 27.5367 21.9688 27.5605 22.0514L29.2081 21.9451H29.7217C30.1728 21.9451 30.4646 21.9723 30.6112 22.0199L30.612 22.0201C30.8777 22.104 31.0757 22.233 31.2128 22.4034L31.2134 22.4041C31.3523 22.5735 31.4682 22.8649 31.5519 23.2935L31.5677 23.3743H31.6501H31.8166H31.9166V23.2743V21.5V21.4H31.8166H26.8422H26.7422V21.5V21.6637V21.7637H26.8422H27.0394C27.219 21.7637 27.3474 21.8023 27.4356 21.8678L27.4355 21.8679L27.4373 21.8691Z" stroke="white" stroke-opacity="0.6" stroke-width="0.2"/>
<path d="M12.4385 20.3358C14.501 20.3358 16.1885 18.6853 16.1885 16.6679C16.1885 14.6506 14.501 13 12.4385 13C10.376 13 8.68848 14.6506 8.68848 16.6679C8.68848 18.6853 10.376 20.3358 12.4385 20.3358ZM19.9385 26.6263C19.9385 25.1408 19.0385 23.8204 17.651 23.2335C16.0572 22.5549 14.2947 22.1698 12.4385 22.1698C10.5822 22.1698 8.81973 22.5549 7.22598 23.2335C5.83848 23.8204 4.93848 25.1408 4.93848 26.6263V27.6717H19.9385V26.6263Z" fill="black" fill-opacity="0.2"/>
<ellipse cx="42.2838" cy="23.9245" rx="4.01235" ry="3.92453" fill="white" fill-opacity="0.5"/>
<path d="M42.2834 23.9234C42.8061 23.9234 43.2337 23.5052 43.2337 22.9939C43.2337 22.4827 42.8061 22.0645 42.2834 22.0645C41.7607 22.0645 41.3331 22.4827 41.3331 22.9939C41.3331 23.5052 41.7607 23.9234 42.2834 23.9234ZM44.184 25.5175C44.184 25.1411 43.9559 24.8065 43.6043 24.6577C43.2004 24.4858 42.7538 24.3882 42.2834 24.3882C41.813 24.3882 41.3664 24.4858 40.9625 24.6577C40.6109 24.8065 40.3828 25.1411 40.3828 25.5175V25.7824H44.184V25.5175Z" fill="#7A6188"/>
<path d="M4.93848 5.43359L46.2965 5.4336" stroke="black" stroke-opacity="0.2" stroke-width="2.41509"/>
<path d="M20.9873 12.2832H46.2959M20.9873 16.5096H38.8885" stroke="black" stroke-opacity="0.2" stroke-width="2.41509"/>
<defs>
<linearGradient id="paint0_linear_551_6180" x1="50" y1="4.96552" x2="15.5492" y2="41.3103" gradientUnits="userSpaceOnUse">
<stop stop-color="#E1C2D2"/>
<stop offset="0.57" stop-color="#D5EBF8"/>
</linearGradient>
</defs>
      </svg>
    );
  };
  

