import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  resendPinService,
  validateOtp,
} from "../../../../service/auth/auth";
import { handleErrors } from "helpers/handleErrors";
import { SuccessToast } from "components/Toasts/Toasts";

export const usePin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resendPinOffset, setResendPinOffset] = useState(false);
  const [open, setOpen] = useState(true);

  const handlePin = async (otp) => {
    setLoading(true);
    const data = {
      token: otp,
      email: sessionStorage.getItem("newcheckEmail"),
      mode: sessionStorage.getItem("mode"),
    };

    try {
      const result = await validateOtp(data);
      
      // Validar que result.data y result.data.data existan
      if (result.data && result.data.data) {
        sessionStorage.setItem("newCheckToken", result.data.accessToken);
        sessionStorage.setItem("phone", result.data.data.phone);
        sessionStorage.setItem("company", result.data.data.company);

        if (result.data.nextView === "0") {
          // paso 0 - seleccion de producto
          return navigate("/formulario");
        }
        if (result.data.nextView === "1") {
          // paso 1 - vista onboarding / Estatus
          return navigate("/pasos", { state: result.data });
        }
      } else {
        console.error("La estructura de respuesta no es válida:", result);
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const resendPin = async () => {
    setLoading(true);
    const data = {
      email: sessionStorage.getItem("newcheckEmail"),
      mode: sessionStorage.getItem("mode"),
    };

    if (resendPinOffset) {
      SuccessToast("Debe de esperar 30 segundos para volver a enviar el pin");
      return;
    }

    try {
      const res = await resendPinService(data);
      SuccessToast(res.data.message ?? "Se ha enviado a su correo un nuevo pin");
      setResendPinOffset(true);
      setTimeout(() => {
        setResendPinOffset(false);
      }, 30000);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  return { handlePin, open, setOpen, loading, resendPin };
};