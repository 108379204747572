import React, { useState, memo } from "react";
import "../DocIdStep.scss";
import Footer from "templates/FormTemplate/components/Footer/Footer";
import Navbar from "components/Navbar/Navbar";
import { postImageForm } from "service/newNaturalClient/formOne";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { useDispatch } from "react-redux";
import ErrorCToast from "components/ErrorToast/ErrorCToast";
import IconId from "../../../../../assets/icons/id-scan.svg";
import Card from "components/Card/Card";
import CedPana from "../../../../../assets/icons/cedPana.svg";
import Pasaport from "../../../../../assets/icons/pasaport.svg";
import CedJuv from "../../../../../assets/icons/cedJuv.svg";
import CarMig from "../../../../../assets/icons/CarMig.svg";
import Chevron from "../../../../../assets/icons/chevronRigth.svg";

const DocsOptions = memo(() => {
  const [Picture, setPicture] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [firstScreen, setFirstScreen] = useState("SECOND");
  const [selectedDocumentId, setSelectedDocumentId] = useState(null); // To store selected document type
  const dispatch = useDispatch();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPicture(reader.result);
        setFirstScreen("THREE"); // Change to "THREE" to show preview
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSentPhoto = async () => {
    setLoading(true);
    const body = {
      requestNumber: sessionStorage.getItem("requestNumber"),
      typeDocument: selectedDocumentId, // Add the selected typeDocument
      front: Picture, // The captured image
      back: "", // Default back to empty
    };

    // Set back field if needed based on document type
    if (selectedDocumentId === 3 || selectedDocumentId === 4) {
      // Cédula Juvenil or Carné de Migración
      body.back = ""; // Set back to empty; can be updated if an image is captured
    }

    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const res = await postImageForm("document", formData);
      dispatch(setNewNaturalStep(parseInt(res?.data?.nextView)));
      dispatch(setFormTrackInfo(res?.data));
      localStorage.setItem("User:", res?.data?.data.names);
      sessionStorage.setItem("User:", res?.data?.data.names);
      console.log("NOMBRE:", res?.data?.data.names);
    } catch (error) {
      ErrorCToast(error);
      setFirstScreen("SECOND"); // Go back to the "SECOND" screen
    } finally {
      setLoading(false);
    }
  };

  const handleRepit = () => {
    setFirstScreen("SECOND");
    setPicture(undefined); // Reset the image
    setSelectedDocumentId(null); // Reset the selected document ID
  };

  const handleCardClick = (docType) => {
    setSelectedDocumentId(docType); // Set the selected document type
    document.getElementById("camera-input").click();
  };

  return (
    <div className="vista">
      {firstScreen === "SECOND" && (
        <>
          <Navbar title="Captura de cédula" noBackbutton={true} />
          <div className="container-documentos">
            <p className="text-doc">
              Seleccione el tipo de Documento de Identidad que va a escanear
            </p>
          </div>
          <input
            type="file"
            accept="image/*"
            capture="environment"
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="camera-input"
          />
          <Card onClick={() => handleCardClick(1)}> {/* Cédula Panameña */}
            <img src={CedPana} alt="Icon" />
            <p className="title-doc">Cédula Panameña</p>
            <img src={Chevron} alt="Icon" />
          </Card>
          <Card onClick={() => handleCardClick(2)}> {/* Pasaporte */}
            <img src={Pasaport} alt="Icon" />
            <p className="title-doc">Pasaporte</p>
            <img src={Chevron} alt="Icon" />
          </Card>
          {/* <Card onClick={() => handleCardClick(3)}> 
            <img src={CedJuv} alt="Icon" />
            <p className="title-doc">Cédula Juvenil</p>
            <img src={Chevron} alt="Icon" />
          </Card>
          <Card onClick={() => handleCardClick(4)}> 
            <img src={CarMig} alt="Icon" />
            <p className="title-doc">Carné de Migración</p>
            <img src={Chevron} alt="Icon" />
          </Card> */}
        </>
      )}
      {firstScreen === "THREE" && (
        <>
          <Navbar title="Captura de cédula" noHomebutton={true} />
          <div className="container-preview">
            <img src={Picture} alt="preview" className="image-preview" />
          </div>
          <Footer
            onClick={handleSentPhoto}
            loading={loading}
            preview
            handleRepit={handleRepit}
          />
        </>
      )}
    </div>
  );
});

DocsOptions.displayName = "DocsOptions";
export default DocsOptions;