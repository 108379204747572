import { Button, Heading, Text } from "@chakra-ui/react";
import { memo, useEffect } from "react";
import "./RiskStep.scss";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setNewNaturalStep } from "redux/features/newNaturalClient";
import Icon from "components/icons";
import Navbar from "components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "components/Toasts/Toasts";

const RiskStep = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trackData } = useSelector((store) => store.newNaturalClient);
  const user = sessionStorage.getItem("User:")
  const handleRedirect = () => {
    // window.location.href = trackData?.data?.href;
    navigate("/password")
   
  };

  useEffect(() => {
    const getFirtsTimeReload = () => {
      const firstTime = sessionStorage.getItem("first-newtech");
      const mode = sessionStorage.getItem("mode");
      if (!firstTime) {
        sessionStorage.setItem("first-newtech", "true");
      } else {
        navigate("/password");
        // navigate(`/mode/${mode}`);
      }
    };
    getFirtsTimeReload();
    return () => {
      dispatch(setNewNaturalStep(1));
      getFirtsTimeReload();
    };
  }, []);

  const handleCopyToClipboard = () => {
    const textToCopy = trackData?.data?.request || "";
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        SuccessToast("Número de trámite copiado exitosamente");
      })
      .catch((error) => {
        ErrorToast("Error al copiar al portapapeles");
        console.error("Error al copiar al portapapeles:", error);
      });
  };
  return (
    <div className="body">
      {/* <Navbar noBackbutton title="Frecuencia de Movimientos" /> */}

      <div className="head-container">
        <CheckCircleIcon w={70} h={70} color="cTeal.500" />
        <Heading
          className="title-conf"
        >
          ¡{user}, hemos validado su identidad correctamente!
        </Heading>
        <Text
          className="text-conf"
        >
          Recibirá un mensaje de texto en su celular con un código de verificación y podrá configurar su PIN y foto de Perfil.
        </Text>
      </div>

      <div className="middle-container">
        <Text
          className="tramite"
        >
          Su número de trámite es:
        </Text>
        <Button
          rightIcon={<Icon name="CopyIcon" />}
          colorScheme="UniOrange"
          variant="outline"
          onClick={handleCopyToClipboard}
        >
          {trackData?.data?.request}
        </Button>
      </div>

      <div className="one-button-footer background">
        <Button
          size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
          fontWeight="600"
          bg="UniOrange.600"
          colorScheme="UniOrange"
          color="UniOrange.50"
          onClick={handleRedirect}
        >
          {/* {trackData?.data?.message} */}
          Continuar
        </Button>
      </div>
    </div>
  );
});
RiskStep.displayName = "RiskStep";
export default RiskStep;
