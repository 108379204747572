import { memo, useState } from "react";
import "./Card.scss";

const Card = memo(({ children, color = "white", onClick }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked); // Cambia el estado al hacer clic
    if (onClick) {
      onClick(); // Llama a la función onClick pasada como prop
    }
  };

  let className = "card";
  className += ` ${color}`;
  className += isClicked ? " clicked" : ""; // Aplica la clase si está clickeado

  return (
    <div className={className} onClick={handleClick}>
      {children}
    </div>
  );
});

Card.displayName = "Card";
export default Card;