import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import RiskStep from "./components/RiskStep/RiskStep";
import FinancialProfileForm from "./components/FinancialProfileStep/components/FinancialProfileForm";
import LaboralDataForm from "./components/LaboralDataStep/components/LaboralDataForm";

/* import { useNewNaturalClient } from "./hooks/useNewNaturalClient";
import PepForm from "./components/PepStep/components/PepForm"; 
import RelatedPepForm from "./components/RelatedPepStep/components/RelatedPepForm"; */

import DocIdStep from "./components/DocIdStep/DocIdStep";
import FormTemplate from "templates/FormTemplate/FormTemplate";
import PepDataStep from "./components/PepDataStep/PepDataStep";
import OcrStep from "./components/OcrStep/OcrStep";
import AddressDataStep from "./components/AddressDataStep/AddressDataStep";
import { useNavigate } from "react-router-dom";
import LivenessStep from "./components/SelfiStep/SelfieStep";
import PasswordComponent from "./components/PasswordStep/PasswordProfile";
/* import AutoSelfie from "./components/AutoSelfiStep/AutoSelfieStep"; */

const NewNaturalClient = memo(() => {
  const navigate = useNavigate();
  const { step, loadingTrackData } = useSelector(
    (store) => store.newNaturalClient
  );

  useEffect(() => {
    const getFirtsTimeReload = () => {
      const mode = sessionStorage.getItem("mode");
      const firstTime = sessionStorage.getItem("first-newtech");
      if (firstTime) return navigate(`/mode/${mode}`);
    };
    window.scrollTo(0, 0);
    getFirtsTimeReload();
  }, []);
  if (loadingTrackData) return <>loading</>;
  
  if (step === 10)
    return (
      <FormTemplate inputForm>
        <PasswordComponent />
      </FormTemplate>
    );

  return (
    <FormTemplate inputForm={step > 3}>
      {/* {step === 2 && !loadingTrackData && <AutoSelfie />} */}
      {step === 2 && !loadingTrackData && <LivenessStep	 />}{" "}
      {/* Comienza en dos porq asi viene de back */}
      {step === 3 && !loadingTrackData && <DocIdStep />}
      {step === 4 && !loadingTrackData && <OcrStep />}
      {step === 5 && !loadingTrackData && <AddressDataStep />}
      {step === 6 && !loadingTrackData && <PepDataStep />}
      {step === 7 && !loadingTrackData && <LaboralDataForm />}
      {step === 8 && !loadingTrackData && <FinancialProfileForm />}
      {step === 9 && !loadingTrackData && <RiskStep />}
    </FormTemplate>
  );
});
NewNaturalClient.displayName = "NewNaturalClient";
export default NewNaturalClient;
