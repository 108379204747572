import React, { memo, useState, useEffect } from "react";
import Navbar from "components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { postImageForm } from "service/newNaturalClient/formOne";
import ErrorCToast from "components/ErrorToast/ErrorCToast";
import { SuccessToast } from "components/Toasts/Toasts";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { Amplify } from 'aws-amplify';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { ThemeProvider, Loader, useTheme, Theme, ToggleButtonGroup, ToggleButton } from '@aws-amplify/ui-react';
import awsexports from '../../../../aws-exports';
import IconFace from "../../../../assets/icons/face-scan.svg";
import BadSelfie from "../../../../assets/icons/bad-selfie.svg";
import IdealSelfie from "../../../../assets/icons/ideal-selfie.svg";
import CompareIcon from "../../../../assets/icons/compare-icon.svg";
import Icon from "../../../../assets/icons/info-warning.svg"

import "./selfie.scss";
import "../../../../styles/style.scss";
import "../../../../styles/colors.scss";
import '@aws-amplify/ui-react/styles.css';
import { Row } from "antd";


const LivenessStep = memo(() => {
  const dispatch = useDispatch();
  const [prevScreen, setPrevScreen] = useState('FIRST');
  const [errorSelfie, setErrorSelfie] = useState('Ha ocurrido un error en el proceso. Intenta entrar en el marco.');
  const [createLivenessApiData, setCreateLivenessApiData] =
    React.useState({ sessionId: null });
  const location = useLocation();

  Amplify.configure(awsexports);

  const [idSessionEffect, setIdSessionEffect] = useState(true)
  const [image64, setImage64] = useState(null);
  const [language, setLanguage] = useState('es');

  useEffect(() => {
    const fetchCreateLiveness = async () => {
      const resp = await fetch(`https://app12.developerpa.com/aws/createSession`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const dta = await resp.json();

      // await new Promise((r) => setTimeout(r, 2000));
      const mockResponse = { sessionId: dta.sessionId };
      const data = mockResponse;
      setCreateLivenessApiData(data);
    };
    fetchCreateLiveness();

  }, [idSessionEffect]);


  const handleAnalysisComplete = async () => {
    try {

      const response = await fetch(`https://app12.developerpa.com/aws/sessionResults/${createLivenessApiData.sessionId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(

      );
      const data = await response.json();
      setImage64(data.getBytes);
      console.log(data.Confidence);

      //PORCENTAJE DE COINCIDENCIA DE VIDA 
      console.log ("ERROR DE VIDA:", data)
      if (data.Confidence < 70) {
        setPrevScreen('ERROR');
        console.log ("ERROR DE VIDA:", data.Confidence)
     
        return;
      };
      const body = {
        requestNumber: location.state.requestNumber,
        image: `data:image/jpg;base64,${data.getBytes}`,
      };
      const formData = new FormData();

      Object.entries(body).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const res = await postImageForm("selfie", formData);
      console.log("RESP " + JSON.stringify(res));
      sessionStorage.setItem("requestNumber", location.state.requestNumber);
      SuccessToast('Registro biometrico exitoso');
      dispatch(setNewNaturalStep(parseInt(res?.data?.nextView)));
      dispatch(setFormTrackInfo(res?.data));




    } catch (error) {
      console.log('errormee ' + error.message);
      setErrorSelfie(error.response.data.message);
      ErrorCToast(error);
      setPrevScreen('ERROR');
    } finally {


    }
  };

  return (
    <div>
      {(prevScreen === 'FIRST') && (
        <div>
          <Navbar title="Captura de selfie" />
          <div className="container">
            <div style={{ height: "150px" }}></div>
            <img id="face-logo" src={IconFace} />
            <p className="text-bio">

            Prepárese para capturar su rostro <br />con tecnología biométrica
             
            </p>

          </div>
          <div style={{
            padding: "0px 20px"
          }}>

            <button
              style={{ marginTop: "100px" }}
              className="big-button"
              onClick={() => {
                setPrevScreen('SELFIE');
                setIdSessionEffect(!idSessionEffect);
              }}
            >
              Empezar
            </button>
          </div>
        </div>
      )}
      {
        (createLivenessApiData.sessionId !== null) ? (prevScreen === 'SELFIE') && (
          <ThemeProvider>
            <FaceLivenessDetector
              sessionId={createLivenessApiData.sessionId}
              region="us-east-1"
              onAnalysisComplete={handleAnalysisComplete}
              onUserCancel={() => {
                setErrorSelfie('Has cancelado el proceso.')
                setPrevScreen('ERROR');
                setIdSessionEffect(!idSessionEffect);
              }
              }

              disableStartScreen={true}

              onError={(err) => {
                console.log("ERROR LIVE:", err)
                setIdSessionEffect(!idSessionEffect);
              }}
            />
          </ThemeProvider>
        ) : (
          <h1></h1>
        )
      }

      {(prevScreen === 'ERROR') && (
        <div>
          <div className="error-container" >
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              padding: "20px",
              margin: "20px 20px",
              backgroundColor: "#FFEEED",
              borderRadius: "20px"
            }}>
              <Row>
                <div style={{
                  marginRight: "8px",

                }}>
                  <img src={Icon} alt="Infored" />
                </div>
                <div style={{
                  textAlign: "start",
                  flex: "1"

                }}>

                  <p style={{}}>
                    Error relacionado a tu biometría
                  </p>
                  <p style={{ fontWeight: "400" }}>
                    {errorSelfie}
                  </p>

                </div>
              </Row>
            </div>

            <Row style={{ marginBottom: "12px" }}>



              {(image64) && <div className="image-container"><img className="img" src={`data:image/jpeg;base64,${image64}`} /></div>}


              {(!image64) && <div className="image-container"><img className="img" src={BadSelfie} /></div>}

              <div style={{
                width: "20px"
              }}></div>
              <img className="image-container" src={IdealSelfie} />
            </Row>
            <Row>
              <p style={{ width: "115px" }}>Su selfie</p>
              <img style={{ width: "20px" }} src={CompareIcon} alt="Infored" />
              <p style={{ width: "115px" }}>Pose ideal</p>

            </Row>

            <p style={{ marginTop: "44px" }}>Nota:</p>
            <p style={{ marginTop: "5px", fontWeight: "400" }}>Use una mejor postura e iluminación</p>

          </div>
          <div style={{
            padding: "0px 20px"
          }}>
            <button
              style={{ marginTop: "48px" }}
              className="big-button"
              onClick={() => {
                setPrevScreen('FIRST');
                setIdSessionEffect(!idSessionEffect);
              }}
            >
              Volver a intentarlo
            </button>
          </div>

        </div>
      )

      }
    </div>


  );
}
);
LivenessStep.displayName = "SelfieStep";
export default LivenessStep;