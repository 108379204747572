import { memo, useState } from "react";
import { Card } from "antd";
import { 
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import "./PasswordChange.scss";

const PasswordForm = memo(({ setPassword }) => {
  const [password, setLocalPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordV, setShowPasswordV] = useState(false);
  const [error, setError] = useState("");

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setLocalPassword(newPassword);
    setPassword(newPassword); // Actualiza el estado en PasswordChange
    validatePassword(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    validatePassword(password, newConfirmPassword);
  };

  const validatePassword = (pwd, confirmPwd) => {
    if (pwd.length < 8) {
      setError("La contraseña debe tener al menos 8 caracteres.");
    } else if (!/[A-Z]/.test(pwd)) {
      setError("La contraseña debe contener al menos una letra mayúscula.");
    } else if (!/[a-z]/.test(pwd)) {
      setError("La contraseña debe contener al menos una letra minúscula.");
    } else if (!/[0-9]/.test(pwd)) {
      setError("La contraseña debe contener al menos un número.");
    } else if (pwd !== confirmPwd) {
      setError("Las contraseñas no coinciden.");
    } else {
      setError(""); // Resetea el mensaje de error si todo es válido
    }
  };

  return (
    <div className="password-form">
      <Card className="password-card">
        <div className="input-group">
          <label className="label-pass">Crear contraseña</label>
          <InputGroup>
            <Input
              type={showPasswordV ? "text" : "password"}
              placeholder="Ej. ********"
              value={password}
              onChange={handlePasswordChange}
              className={error ? "input-error" : ""}
            />
            <InputRightElement>
              {showPasswordV ? (
                <ViewOffIcon
                  cursor="pointer"
                  color="gray.500"
                  _hover={{ color: "gray.600" }}
                  onClick={() => setShowPasswordV(!showPasswordV)}
                  
                />
              ) : (
                <ViewIcon
                  cursor="pointer"
                  color="gray.500"
                  _hover={{ color: "gray.600" }}
                  onClick={() => setShowPasswordV(!showPasswordV)}
                />
              )}
            </InputRightElement>
          </InputGroup>
        </div>

        <div className="input-group-pass">
          <label className="label-pass">Repetir contraseña</label>
          <InputGroup>
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Ej. ********"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className={error && confirmPassword === "" ? "input-error" : ""}
            />
            <InputRightElement>
              {showPassword ? (
                <ViewOffIcon
                  cursor="pointer"
                  color="gray.500"
                  _hover={{ color: "gray.600" }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <ViewIcon
                  cursor="pointer"
                  color="gray.500"
                  _hover={{ color: "gray.600" }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </InputRightElement>
          </InputGroup>
        </div>

        {error && <Text color="red.500" className="text-error-pass">{error}</Text>} {/* Muestra el mensaje de error si existe */}
      </Card>
    </div>
  );
});

PasswordForm.displayName = "PasswordForm";
export default PasswordForm;