import { memo } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NotFound from "pages/404/NotFound";
import Dashboard from "pages/Dashboard/Dashboard";
import NewNaturalClient from "pages/NewNaturalClient/NewNaturalClient";
import CompanySelector from "pages/auth/CompanySelector/CompanySelector";
import InsertPin from "pages/auth/InsertPin/InsertPin";
import Login from "pages/auth/Login/Login";
import InsertPhonePin from "pages/auth/InsertPhonePin/InsertPhonePin";
import WelcomeOne from "pages/auth/Welcome/Welcome-one";
import WelcomeTwo from "pages/auth/Welcome/Welcome-two";
import WelcomeThree from "pages/auth/Welcome/Welcome-three";
import DocIdStep from "pages/NewNaturalClient/components/DocIdStep/DocIdStep";
import DocsOptions from "pages/NewNaturalClient/components/DocIdStep/Docs/Docs";
import PasswordComponent from "pages/NewNaturalClient/components/PasswordStep/PasswordProfile";
import RiskStep from "pages/NewNaturalClient/components/RiskStep/RiskStep";

// import LivenessStep from "pages/NewNaturalClient/components/SelfiStep/SelfieStep";
// import { LivenessError } from "pages/NewNaturalClient/components/SelfiStep/componentes/LivenessError";


const Router = memo(() => {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/mode/:type/:requestId/:phone" element={<Login />} />
        <Route path="/ingresar-pin" element={<InsertPin />} />
        <Route path="/formulario" element={<CompanySelector />} />
        <Route path="/ingresar-pin-telefonico" element={<InsertPhonePin />} />
        <Route path="/pasos" element={<Dashboard />} />
        <Route path="/docs" element={<DocsOptions />} />
        <Route path="/end" element={<RiskStep />} />
        <Route path="/password" element={<PasswordComponent/>} />
        {/* <Route path="/selfie" element={<LivenessStep />} />
        <Route path="/selfie-error" element={<LivenessError />} /> */}
        <Route
          path="/persona-natural/registro"
          element={<NewNaturalClient />}
        />
        <Route
          path="/:requestId"
          element={<WelcomeOne />}
        />
        <Route
          path="/welcome-two"
          element={<WelcomeTwo />}
        />
        <Route
          path="/welcome-three"
          element={<WelcomeThree />}
        />


        <Route path="/*" element={<NotFound />} />
       
        <Route path="/" element={<Navigate to="/mode/BANA/:requestId/:phone"/>} />
      </Routes>
    </BrowserRouter>
  );
});
Router.displayName = "Router";
export default Router;

/* useEffect(() => {
    // FUNCION PARA BLOQUEAR EL GO BACK 

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    // FUNCION PARA BLOQUEAR EL GO BACK 
  }, []); */
