import { memo } from "react";
import MainTemplate from "templates/MainTemplate/MainTemplate";
import { Button } from "@chakra-ui/react";
import { Col, Row } from "antd";
import Icon from "components/icons";
import "./Dashboard.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { onboardingTrack } from "service/auth/auth";
import ErrorCToast from "components/ErrorToast/ErrorCToast";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { useDispatch } from "react-redux";

// Constantes para estados y pasos
const STATUS = [
  {
    label: "Pendiente",
    value: 1,
  },
  {
    label: "Empezar",
    value: 2,
  },
  {
    label: "Listo",
    value: 3,
  },
];

const STEPS = [
  {
    label: "Captura de selfie",
    status: 1, // 3 es listo
    page: 2,
    blockPage: 3,
    image: <Icon name="StepsFaceIcon" />,
  },
  {
    label: "Captura de documento ID",
    status: 1,
    page: 3,
    blockPage: 3,
    image: <Icon name="StepPassportIcon" />,
  },
  {
    label: "Confirmación de datos ",
    status: 1, // activo para empezar
    page: 4,
    blockPage: undefined,
    image: <Icon name="StepsDataIcon" />,
  },
  {
    label: "Dirección de residencia",
    status: 1, // sin empezar
    page: 5,
    blockPage: undefined,
    image: <Icon name="StepsHouseIcon" />,
  },
  {
    label: "Declaración PEP",
    status: 1,
    page: 6,
    blockPage: undefined,
    image: <Icon name="StepPepIcon" />,
  },
  {
    label: "Datos laborales",
    status: 1,
    page: 7,
    blockPage: undefined,
    image: <Icon name="StepLaboralDataIcon" />,
  },
  {
    label: "Frecuencia de movimientos",
    status: 1,
    page: 8,
    blockPage: undefined,
    image: <Icon name="StepFinancialIcon" />,
  },
  {
    label: "Crear contraseña",
    status: 1,
    page: 9,
    blockPage: undefined,
    image: <Icon name="PasswordIcon" />,
  },
];
// Definición del componente Dashboard, memoizado para evitar renderizados innecesarios
const Dashboard = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

 // Función para manejar la edición de un paso
  const handleEdit = async (view) => {
    try {
      const res = await onboardingTrack({ view });
      return navigate("/formulario", { state: { ...res.data, navbar: true } });
    } catch (error) {
      ErrorCToast(error);
    }
  };
 // Función para manejar la navegación a un paso
  const handleNavigate = async (view) => {
    const body = { view };
    try {
      const res = await onboardingTrack(body);
      dispatch(setFormTrackInfo(res.data));
      dispatch(setNewNaturalStep(parseInt(res.data.nextView)));
      navigate("/persona-natural/registro", { state: res.data });
    } catch (error) {
      ErrorCToast(error);
    }
  };

  return (
    <MainTemplate
      title="¡Bienvenido de vuelta!"
      subtitle= {`Continúa el proceso donde te habías quedado con tu teléfono ${location.state.data.phone}`}
      bodyBorderless
    >
      {/* <Row
        className="header-steps"
        style={{ padding: 20 }}
        justify={"space-between"}
      >
        <Col>
          <Row>
            <span className="details" >{location.state.data.product}</span>
          </Row>
          <Row>
            <span className="details" >{`${location.state.data.areaCode}-`}</span>
          </Row>
        </Col>
        <Col>
          <div onClick={() => handleEdit(0)} className="edit-button">
            Editar <Icon name="PencilIcon" />
          </div>
        </Col>
      </Row> */}
      {STEPS.map((res, key) => {
        const renderVariation = () => {
          const step = location.state.stopper;
          if (step && parseInt(step) > res.page - 1) return 3;
          if (step && parseInt(step) === res.page - 1) return 2;
          return 1;
        };

        const renderVariation2 = () => {
          const step = location.state.stopper;
          const { blockPage } = res;

          if (step && blockPage && parseInt(step) >= blockPage) return "block";
          if (step && parseInt(step) < res.page - 1) return "block";
          return "";
        };

        const handleNavigate = async (view) => {
          const body = { view };
          const disabled = renderVariation2();
          if (disabled === "block") return;
          try {
            const res = await onboardingTrack(body);
            dispatch(setFormTrackInfo(res.data));
            dispatch(setNewNaturalStep(parseInt(res.data.nextView)));
            navigate("/persona-natural/registro", { state: res.data });
          } catch (error) {
            ErrorCToast(error);
          }
        };

        return (
          <Row
            key={"step-option" + key}
            className="step-option"
            data-variation={renderVariation()}
            onClick={() => handleNavigate(res.page)}
          >
            <Col span={5}>
              <div className="image-step" data-variation={renderVariation()}>
                {res.image}
              </div>
            </Col>
            <Col span={14}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <p style={{textAlign: "start"}}
                    data-variation={renderVariation()}
                    className={renderVariation2()}
                  >
                    {res.label}
                  </p>
                </Col>
                <Col span={24}>
                  <span className="tag" data-variation={renderVariation()}>
                    {STATUS[renderVariation() - 1].label}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              span={5}
            >
              <div className="action-button" data-variation={renderVariation()}>
                {renderVariation() === 1 && <Icon name="Lock" />}
                {renderVariation() === 2 && <Icon name="TinChevron" />}
                {renderVariation() === 3 && <Icon name="TinCheck" />}
              </div>
            </Col>
          </Row>
        );
      })}
      <div style={{height: "100px"}}></div>
      <div className="one-button-footer">
        <Button
         
         size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
          fontWeight="600"
          bg="UniOrange.500"
          colorScheme="UniOrange"
          color="UniOrange.50"
          width="100%"
          onClick={() => handleNavigate(null)}
        >
          Siguiente
        </Button>
      </div>
    </MainTemplate>
  );
});

// Asignación de nombre al componente para propósitos de depuración
Dashboard.displayName = "Dashboard";
export default Dashboard;
