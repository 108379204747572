import { memo, useState } from "react";
import { Button, Heading } from "@chakra-ui/react";
import "./PasswordChange.scss";
import User from "../../../../../assets/icons/perm_identity.svg";
import Navbar from "components/Navbar/Navbar";
import PasswordForm from "./PasswordForm";
import { postCreatePassword } from "service/newNaturalClient/general";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordChange = memo(() => {
  const [password, setPassword] = useState("");
  const phone = sessionStorage.getItem("phone")


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password) {
      try {
        const response = await postCreatePassword({ passwordNovoPayment: password });
        console.log("Password created successfully:", response);
        toast.success("Contraseña creada exitosamente");
      
        window.location.href = "https://www.newtech.net/";
        
      } catch (error) {
        console.error("Error creating password:", error);
        
  
        if (error.response && error.response.data) {
          const { message } = error.response.data;
          toast.error(message); // Utiliza una función para mostrar el mensaje de error
        } else {
          toast.error("Error al crear la contraseña"); // Mensaje de error general
        }
      }
    }
  };

  return (
    <div className="container-pass">
      <Navbar title="Contraseña" noHomebutton={true} noBackbutton={true} />
      <Heading className="title-data">Crea tu contraseña</Heading>
      <div className="user">
        <div className="user-user">
          <img src={User} alt="user" /> Tu usuario es:
        </div>
        <div className="cod">{phone}</div>
      </div>
      <form className="form-password" onSubmit={handleSubmit}>
        <div className="password-form">
          <PasswordForm setPassword={setPassword} />
        </div>
        <div className="one-button-footer background">
          <Button
            size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
            fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
            fontWeight="600"
            bg="UniOrange.500"
            colorScheme="UniOrange"
            color="UniOrange.50"
            type="submit"
          >
            Crear contraseña y finalizar
          </Button>
        </div>
      </form>
<ToastContainer/>
    </div>
  );
});

PasswordChange.displayName = "PasswordChange";
export default PasswordChange;