import React, { useState, memo, useRef } from "react";
import "./DocIdStep.scss";
import Footer from "templates/FormTemplate/components/Footer/Footer";
import Navbar from "components/Navbar/Navbar";
import { postImageForm } from "service/newNaturalClient/formOne";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { useDispatch } from "react-redux";
import ErrorCToast from "components/ErrorToast/ErrorCToast";
import Card from "components/Card/Card";
import CedPana from "../../../../assets/icons/cedPana.svg";
import Pasaport from "../../../../assets/icons/pasaport.svg";
import Chevron from "../../../../assets/icons/chevronRigth.svg";

const DocIdStep = memo(() => {
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("SECOND");
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const dispatch = useDispatch();
  const frontInputRef = useRef(null);
  const backInputRef = useRef(null);

  // Maneja la captura de imágenes y las convierte a Base64
  const handleImageChange = (event, isBack) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result; // Esto incluye el prefijo data:image/...
        if (isBack) {
          setBackImage(base64String);
        } else {
          setFrontImage(base64String);
          setCurrentScreen("THREE");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSentPhoto = async () => {
    if (!frontImage || !backImage) {
      ErrorCToast("Por favor, capture ambas imágenes.");
      return;
    }

    setLoading(true);
    try {
      const body = {
        requestNumber: sessionStorage.getItem("requestNumber"),
        typeDocument: selectedDocumentId,
        front: frontImage, // Base64 con prefijo
        back: backImage, // Base64 con prefijo
      };

      const res = await postImageForm("document", body); // Asume que el endpoint espera JSON
      dispatch(setNewNaturalStep(parseInt(res?.data?.nextView)));
      dispatch(setFormTrackInfo(res?.data));
    } catch (error) {
      ErrorCToast(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRepit = () => {
    setCurrentScreen("SECOND");
    setFrontImage("");
    setBackImage("");
    setSelectedDocumentId(null);
  };

  return (
    <div className="vista">
      {currentScreen === "SECOND" && (
        <>
          <Navbar title="Captura de cédula" noBackbutton={true} />
          <div className="container-documentos">
            <p className="text-doc">
              Seleccione el tipo de Documento de Identidad que va a escanear
            </p>
          </div>

          {/* Input para la parte frontal (oculto) */}
          <input
            type="file"
            accept="image/*"
            capture="environment"
            onChange={(e) => handleImageChange(e, false)}
            ref={frontInputRef}
            style={{ display: "none" }}
          />

          {/* Cards de selección */}
          <Card
            onClick={() => {
              setSelectedDocumentId(1);
              frontInputRef.current.click();
            }}
          >
            <img src={CedPana} alt="Cédula Panameña" />
            <p className="title-doc">Cédula Panameña</p>
            <img src={Chevron} alt=">" />
          </Card>

          <Card
            onClick={() => {
              setSelectedDocumentId(2);
              frontInputRef.current.click();
            }}
          >
            <img src={Pasaport} alt="Pasaporte" />
            <p className="title-doc">Pasaporte</p>
            <img src={Chevron} alt=">" />
          </Card>
        </>
      )}

      {currentScreen === "THREE" && (
        <>
          <Navbar title="Captura de cédula" noHomebutton={true} />
          <div className="three-scroll-container">
            <div className="container-preview">
              <img src={frontImage} alt="Frente" className="image-preview" />
              <button
                className="button-back"
                onClick={() => backInputRef.current.click()}
              >
                Capturar reverso
              </button>
            </div>

            {/* Input para la parte trasera (oculto) */}
            <input
              type="file"
              accept="image/*"
              capture="environment"
              onChange={(e) => handleImageChange(e, true)}
              ref={backInputRef}
              style={{ display: "none" }}
            />

            {backImage && (
              <div className="container-preview-back">
                <img src={backImage} alt="Reverso" className="image-preview" />
              </div>
            )}

            <Footer
              onClick={handleSentPhoto}
              loading={loading}
              preview
              handleRepit={handleRepit}
            />
          </div>
        </>
      )}
    </div>
  );
});

DocIdStep.displayName = "DocIdStep";
export default DocIdStep;
