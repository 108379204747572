import { memo } from "react";
import { Heading } from "@chakra-ui/react";
import { Card } from "antd";
import CSelect from "components/inputs/Select/CSelect";
import CInput from "components/inputs/Input/CInput";
import { useHomeAdress } from "../hooks/useHomeAdress";
import "./styles-data.scss"
// Definición del componente HomeAdress, memoizado para evitar renderizados innecesarios
const HomeAdress = memo(({ homeAdress, setHomeAdress }) => {
  // Uso del hook personalizado para manejar la dirección de residencia
  useHomeAdress({ homeAdress, setHomeAdress });
    // Renderizado del componente
  return (
    <Card
      title={
        <Heading
         className="title-data"
        >
          Dirección de residencia 
        </Heading>
      }
    >
      <form className="form">
        <div className="row wrap">
          <div className="col ">
            <CSelect
              label={homeAdress.countryResident.placeholder}
              name={homeAdress.countryResident.name}
              value={homeAdress.countryResident.value}
              onChange={setHomeAdress}
              placeholder={homeAdress.countryResident.placeholder}
              error={homeAdress.countryResident.error}
              options={homeAdress.countryResident.options}
              rule={homeAdress.countryResident.rule}
            />
          </div>
          <div className="col ">
            {/*Componente personalizado para los campos de selección*/}
            <CSelect
              label={homeAdress.provinceResident.label}
              name={homeAdress.provinceResident.name}
              value={homeAdress.provinceResident.value}
              onChange={setHomeAdress}
              placeholder={homeAdress.provinceResident.placeholder}
              error={homeAdress.provinceResident.error}
              options={homeAdress.provinceResident.options}
              rule={homeAdress.provinceResident.rule}
              disabled={homeAdress.provinceResident.disabled}
            />
          </div>
          <div className="col ">
            {/*Componente personalizado para los campos de entrada*/ }
            <CSelect
              label={homeAdress.cityResident.label}
              name={homeAdress.cityResident.name}
              value={homeAdress.cityResident.value}
              onChange={setHomeAdress}
              placeholder={homeAdress.cityResident.placeholder}
              error={homeAdress.cityResident.error}
              options={homeAdress.cityResident.options}
              rule={homeAdress.cityResident.rule}
              disabled={homeAdress.cityResident.disabled}
            />
          </div>

          {/* ROW */}
          <div className="col ">
            <CSelect
              label={homeAdress.district.label}
              name={homeAdress.district.name}
              value={homeAdress.district.value}
              onChange={setHomeAdress}
              placeholder={homeAdress.district.placeholder}
              error={homeAdress.district.error}
              options={homeAdress.district.options}
              rule={homeAdress.district.rule}
              disabled={homeAdress.district.disabled}
            />
          </div>
          {/* <div className="col ">
            <CInput
              label={homeAdress.postalCode.placeholder}
              name={homeAdress.postalCode.name}
              value={homeAdress.postalCode.value}
              onChange={setHomeAdress}
              placeholder={homeAdress.postalCode.placeholder}
              error={homeAdress.postalCode.error}
              type={homeAdress.postalCode.type}
              rule={homeAdress.postalCode.rule}
            />
          </div> */}
          <div className="col">
            <CInput
              label={homeAdress.address.placeholder}
              name={homeAdress.address.name}
              value={homeAdress.address.value}
              onChange={setHomeAdress}
              placeholder={homeAdress.address.placeholder}
              error={homeAdress.address.error}
              type={homeAdress.address.type}
              rule={homeAdress.address.rule}
            />
          </div>
        </div>
      </form>
    </Card>
  );
});
// Asignación de nombre al componente para propósitos de depuración
HomeAdress.displayName = "HomeAdress";

export default HomeAdress;
